import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/price',
    name: 'Price',
    component: () => import('../views/About.vue')
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import('../views/dashboard/index'),
    children: [{
      path: '/dashboard',
      name: 'Main',
      component: () => import('../views/dashboard/main')
    },
    {
      path: '/mytuan',
      name: 'Mytuan',
      component: () => import('../views/kaituan/mytuan')
    },
    {
      path: '/selltuan',
      name: 'Selltuan',
      component: () => import('../views/kaituan/index')
    },
    {
      path: '/tuandetail',
      name: 'tuanDetailhtml',
      component: () => import('../views/kaituan/detailInfo')
    },
    {
      path: '/sendtuan',
      name: 'Sendtuan',
      component: () => import('../views/kaituan/sendtuan')
    },
    {
      path: '/sendtuan/detail',
      name: 'tuansDetail',
      component: () => import('../views/kaituan/detail')
    },
    {
      path: '/sendtuan/detail/:id',
      name: 'tuansDetailId',
      component: () => import('../views/kaituan/detail')
    },
    {
      path: '/goods',
      name: 'Goods',
      component: () => import('../views/good/supplierIndex')
    },
    {
      path: '/goods/detail',
      name: 'addGoods',
      component: () => import('../views/good/detail')
    },
    {
      path: '/goods/detail/:id',
      name: 'GoodsDetail',
      component: () => import('../views/good/detail')
    },
    {
      path: '/dingdan',
      name: 'Orderss',
      component: () => import('../views/order/tuanIndex')
    },
    {
      path: '/dingdan/detail',
      name: 'OrderssDetail',
      component: () => import('../views/order/detailInfo')
    },
    {
      path: '/supdingdan',
      name: 'SupOrderss',
      component: () => import('../views/order/index')
    },
    {
      path: '/supdingdan/detail',
      name: 'SupOrderssDetail',
      component: () => import('../views/order/detailInfo')
    },
    {
      path: '/supplier',
      name: 'Supplier',
      component: () => import('../views/supplier/index')
    },
    {
      path: '/retailer',
      name: 'Retailer',
      component: () => import('../views/mysupplier/index')
    },
    {
      path: '/sell',
      name: 'Sell',
      component: () => import('../views/sell/index')
    },
    {
      path: '/shoplist',
      name: 'Shoplist',
      component: () => import('../views/shop/index')
    },
    {
      path: '/sendmoban',
      name: 'Moban',
      component: () => import('../views/moban/index')
    },
    {
      path: '/sendmoban/detail',
      name: 'MobanDetail',
      component: () => import('../views/moban/detail')
    },
    {
      path: '/freight',
      name: 'FreightMoban',
      component: () => import('../views/moban/freight')
    },
    {
      path: '/freight/detail',
      name: 'FreightDetail',
      component: () => import('../views/moban/fredetail')
    },
    {
      path: '/expressmoban',
      name: 'ExpressMoban',
      component: () => import('../views/moban/express')
    },
    {
      path: '/reboter',
      name: 'Reboter',
      component: () => import('../views/rebot/index')
    },
    {
      path: '/dataanalyse',
      name: 'DataAnalyse',
      component: () => import('../views/data/alyse')
    },
    {
      path: '/everydatas',
      name: 'everyDatas',
      component: () => import('../views/data/mingxi')
    },
    {
      path: '/reboter/contact',
      name: 'ReboterContact',
      component: () => import('../views/rebot/contact')
    },
    {
      path: '/reboter/group',
      name: 'ReboterGroup',
      component: () => import('../views/rebot/group')
    },
    {
      path: '/device',
      name: 'Device',
      component: () => import('../views/device/index')
    },
    {
      path: '/channel',
      name: 'Channel',
      component: () => import('../views/channel/index')
    },
    {
      path: '/channel/detail',
      name: 'ChannelDetail',
      component: () => import('../views/channel/detail')
    },
    {
      path: '/weixinMsg',
      name: 'WeixinMessage',
      component: () => import('../views/weixin/index')
    },
    {
      path: '/team',
      name: 'Teams',
      component: () => import('../views/user/index')
    },
    {
      path: '/system',
      name: 'System',
      component: () => import('../views/system/index')
    },
    {
      path: '/system/detail',
      name: 'SystemDetail',
      component: () => import('../views/system/detail')
    },
    {
      path: '/system/supplier/detail',
      name: 'SystemSupplierDetail',
      component: () => import('../views/system/supplierDetail')
    },
    {
      path: '/platformsku',
      name: 'platformsku',
      component: () => import('../views/platformsku/index')
    },
    {
      path: '/retailergoods',
      name: 'retailergoods',
      component: () => import('../views/good/retailerIndex')
    },
    {
      path: '/suppliergroup',
      name: 'suppliergroup',
      component: () => import('../views/supplier_group/index')
    },
    {
      path: '/suppliergroup/detail',
      name: 'suppliergroupdetail',
      component: () => import('../views/supplier_group/detail')
    },
    {
      path: '/payorder/retailer',
      name: 'retailerpayorder',
      component: () => import('../views/payorder/retailer')
    },
    {
      path: '/payorder/money',
      name: 'supplierpayordermoney',
      component: () => import('../views/payorder/supplierbalance')
    },
    {
      path: '/payorder/index',
      name: 'supplierpayorderindex',
      component: () => import('../views/payorder/supplier')
    },
    {
      path: '/level/of/retailer',
      name: 'levelOfRetailer',
      component: () => import('../views/level/index')
    },
    {
      path: '/yeepay',
      name: 'yeepay',
      component: () => import('../views/payorder/yeepayMerchant')
    },
    {
      path: '/wms/dashboard',
      name: 'wmsDashboard',
      component: () => import('../views/wms/dashboard')
    },
    {
      path: '/wms/location',
      name: 'wmsLocation',
      component: () => import('../views/wms/warehouse/wmsWarehouseIndex')
    },
    {
      path: '/wms/customer',
      name: 'wmsCustomer',
      component: () => import('../views/wms/customer/customerIndex')
    },
    {
      path: '/wms/basic',
      name: 'wmsBasic',
      component: () => import('../views/wms/customer/customerIndex')
    },    
    {
      path: '/wms/goods',
      name: 'wmsSku',
      component: () => import('../views/wms/sku/wmsSkuIndex')
    },    
    {
      path: '/wms/supplier',
      name: 'wmsSupplier',
      component: () => import('../views/wms/supplier/supplierIndex')
    }, 
    {
      path: '/wms/unit',
      name: 'wmsUnit',
      component: () => import('../views/wms/unit/wmsUnitIndex')
    }, 
    {
      path: '/wms/unittype',
      name: 'wmsUnitType',
      component: () => import('../views/wms/unittype/wmsUnitTypeIndex')
    },   
    {
      path: '/wms/property',
      name: 'wmsProperty',
      component: () => import('../views/wms/property/wmsPropertyIndex')
    }, 
    {
      path: '/wms/box',
      name: 'wmsBox',
      component: () => import('../views/wms/box/wmsBoxIndex')
    },           
    {
      path: '/wms/boxmodel',
      name: 'wmsBoxModel',
      component: () => import('../views/wms/boxmodel/wmsBoxModelIndex')
    },           
    {
      path: '/wms/skubrand',
      name: 'wmsSkuBrand',
      component: () => import('../views/wms/brand/wmsSkuBrandIndex')
    },
    {
      path: '/wms/driver',
      name: 'wmsDriver',
      component: () => import('../views/wms/driver/driverIndex')
    },
    {
      path: '/wms/goodsOrder',
      name: 'wmsGoodsOrder',
      component: () => import('../views/wms/goodsorder/wmsGoodsOrderIndex')
    },    
    {
      path: '/wms/temptype',
      name: 'wmsTemperature',
      component: () => import('../views/wms/temperature/wmsTemperatureIndex')
    },
    {
      path: '/wms/bintype',
      name: 'wmsBinType',
      component: () => import('../views/wms/bintype/wmsBinTypeIndex')
    }, 
    {
      path: '/wms/warehouse',
      name: 'wmsWarehouse',
      component: () => import('../views/wms/warehouse/wmsWarehouseIndex')
    },     
    {
      path: '/wms/platform',
      name: 'wmsPlatform',
      component: () => import('../views/wms/platform/wmsPlatformIndex')
    },           
    {
      path: '/wms/area',
      name: 'wmsArea',
      component: () => import('../views/wms/area/wmsAreaIndex')
    },      
    {
      path: '/wms/binLocation',
      name: 'wmsBinLocation',
      component: () => import('../views/wms/binlocation/wmsBinLocationIndex')
    },               
    {
      path: '/wms/stock',
      name: 'wmsStock',
      component: () => import('../views/wms/stock/wmsStockIndex')
    },  
    {
      path: '/wms/stockhistory',
      name: 'wmsStockHistory',
      component: () => import('../views/wms/stockhistory/wmsStockHistoryIndex')
    },
    {
      path: '/wms/inventorycheck',
      name: 'wmsInventoryCheck',
      component: () => import('../views/wms/inventorycheck/wmsInventoryCheckIndex')
    },               
    {
      path: '/wms/transfer',
      name: 'wmsTransfer',
      component: () => import('../views/wms/transfer/wmsTransferIndex')
    },
    {
      path: '/wms/inoutorder',
      name: 'wmsInoutOrder',
      component: () => import('../views/wms/inoutorder/wmsInOutOrderIndex')
    },
    {
      path: '/wms/skucategory',
      name: 'wmsSkucategory',
      component: () => import('../views/wms/skucategory/wmsSkuCategoryIndex')
    },
    {
      path: '/wms/inout/purchase',
      name: 'wmsPurchase',
      component: () => import('../views/wms/purchase/wmsPurchaseOrderIndex')
    },
    {
      path: '/wms/inout/receive',
      name: 'wmsReceive',
      component: () => import('../views/wms/receive/wmsReceiveOrderIndex')
    },
    {
      path: '/wms/inout/inspect',
      name: 'wmsInspect',
      component: () => import('../views/wms/inspect/wmsInspectOrderIndex')
    },
    {
      path: '/wms/inout/inwarehouse',
      name: 'wmsInwarehouse',
      component: () => import('../views/wms/inwarehouse/wmsInOrderIndex')
    },
    {
      path: '/wms/inout/sale',
      name: 'wmsSaleOrder',
      component: () => import('../views/wms/sale/wmsSaleOrderIndex')
    },
    {
      path: '/wms/inout/refund',
      name: 'wmsRefundOrder',
      component: () => import('../views/wms/refund/wmsRefundOrderIndex')
    },
    {
      path: '/wms/inout/refundin',
      name: 'wmsRefundInOrder',
      component: () => import('../views/wms/refundin/wmsRefundInOrderIndex')
    },
    {
      path: '/wms/inout/delivery',
      name: 'wmsDeliveryOrder',
      component: () => import('../views/wms/delivery/wmsDeliveryOrderIndex')
    },
    {
      path: '/wms/sheet',
      name: 'WmsSheet',
      component: () => import('../views/wms/sheet/wmsSheetIndex')
    },
    {
      path: '/wms/printtemplate',
      name: 'WmsPrintTemplate',
      component: () => import('../views/wms/templatestyle/wmsTemplateStyleIndex')
    },
    {
      path: '/wms/printer',
      name: 'wmsPrinter',
      component: () => import('../views/wms/printer/wmsPrinterIndex')
    },
    {
      path: '/wms/worktable',
      name: 'wmsWorkTable',
      component: () => import('../views/wms/worktable/wmsWorkTableIndex')
    },
    {
      path: '/wms/expresstemplate',
      name: 'wmsExpressTemplate',
      component: () => import('../views/wms/expresstemplate/wmsExpressTemplateIndex')
    },
    {
      path: '/wms/expressrule',
      name: 'WmsExpressRule',
      component: () => import('../views/wms/expressrule/wmsExpressRuleIndex')
    },
    {
      path: '/wms/carrierrule',
      name: 'WmsCarrierRule',
      component: () => import('../views/wms/carrierrule/wmsCarrierRuleIndex')
    },
    ]
  },
  {
    path: '*',
    name: '404',
    component: () => import('../views/404/index')
  },
  
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
