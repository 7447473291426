import request from '@/api/request.js'

// 注册接口
export function register (data) {
  return request({
    url: '/api/user/register',
    method: 'POST',
    data
  })
}
// 手机登录接口
export function loginByPhone (data) {
  return request({
    url: '/api/user/loginByPhone',
    method: 'POST',
    data
  })
}
// 登录接口
export function login (data) {
  return request({
    url: '/api/user/loginByUsername',
    method: 'POST',
    data
  })
}
// 发送验证码接口
export function sendMsg (data) {
  return request({
    url: '/api/sms/sendLoginMessage',
    method: 'POST',
    data
  })
}
// 修改密码
export function modiflyPassWord (data) {
  return request({
    url: '/api/user/resetPassword',
    method: 'POST',
    data
  })
}
export function changeMenu (data) {
  return request({
    url: '/api/menu/change',
    method: 'POST',
    data
  })
}

// 退出登录
export function logout () {
  return request({
    url: '/api/user/exit',
    method: 'GET'
  })
}

// 生成二维码
export function QrCode () {
  return request({
    url: '/api/user/generateWeChatMiniProgramQrCode',
    method: 'POST'
  })
}

// 轮循
export function getQrCode (data) {
  return request({
    url: '/api/user/getWeChatMiniProgramQrCodeState?qrCodeId=' + data,
    method: 'GET'
  })
}

export function getUserInfo () {
  return request({
    url: '/api/user/getUser',
    method: 'GET'
  })
}

// 切换账号
export function changeOrganization(data) {
  return request({
    url: '/api/user/changeOrganization',
    method: 'POST',
    data
  })
}