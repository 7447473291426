import axios from 'axios'
import {
  Message,
  MessageBox
} from 'element-ui'
import router from '@/router/index'

const service = axios.create({
  mode: 'no-cors',
  headers: {
    'Access-Control-Allow-Origin': '*'
    // 'content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
  },
  withCredentials: true,
  credentials: 'same-origin',
  timeout: 5000000
})
const curEnv = process.env.NODE_ENV
console.log(curEnv)
let isFirst = true
axios.defaults.validateStatus = function (status) {
  return status >= 200 && status <= 500 // 默认的
}

service.interceptors.request.use(config => {
  const isToken = (config.headers || {}).isToken === false
  const token = window.sessionStorage.getItem('accessToken') || ''
  if (token && !isToken) {
    config.headers['Authorization'] = 'Bearer ' + token // token
  }
  return config
}, error => {
  Promise.reject(error)
})

service.interceptors.response.use(
  response => {
    const res = response.data
    if (response.status !== 200) {
      if (response.status === 426) {
        Message({
          message: res.message,
          type: 'error',
          duration: 5 * 1000
        })
        return Promise.reject(res.message || 'error')
      }
      if (response.status === 500) {
        Message({
          message: res.message,
          type: 'error',
          duration: 5 * 1000
        })
        return Promise.reject(res.message || 'error')
      }
      if (response.status === 401) {
        Message({
          message: '登录过期，请重新登录',
          type: 'error',
          duration: 5 * 1000
        })
        window.sessionStorage.removeItem('accessToken')
        router.push({
          path: '/'
        })
        return Promise.reject(res.message || 'error')
      }
      // console.log('等于8000')
      return Promise.reject(res.message || 'error')
    }else{
      // console.log('等于2000')
      if (response.status == 200 && parseInt(res.code) === 1011004) {
        if (isFirst) {
          isFirst = false
          MessageBox.alert(res.message, '温馨提示', {
            confirmButtonText: '重新登录',
            type: 'warning'
          }).then(() => {
            window.sessionStorage.removeItem('accessToken')
            isFirst = true
            router.push({
              path: '/'
            })
          })
          return
        }
        return false
        
      }else{
        console.log('*&****')
        return res
      }
    }
  },
  error => {
    const errorData = error.response
    Message({
      message: errorData.data.message,
      type: 'error',
      duration: 5 * 1000
    })
    // if (errorData.status === 401) {
    //   Message({
    //     message: '登录过期，请重新登录',
    //     type: 'error',
    //     duration: 5 * 1000
    //   })
    //   window.sessionStorage.removeItem('accessToken')
    //   router.push({
    //     path: '/'
    //   })
    //   return
    // }
    // if (errorData.status === 426) {
    //   Message({
    //     message: errorData.data.message,
    //     type: 'error',
    //     duration: 5 * 1000
    //   })
    //   return Promise.reject(errorData.data.message)
    // }
    return Promise.reject(error)
  }
)

export default service
