<template>
  <div>
    <div class="pmTop" :class="scrollTop>120?'topbgs':''">
      <div class="topbox">
      <router-link to="/"><div class="logo"></div></router-link>
      <div class="admininfo">
        <div class="navbox">
          <router-link to="/"><div :class="[curid==1?'item curItem':'item']">首页</div></router-link>
          <a href="https://file.kaituanyi.com/uploads/1542344600272777217.pdf"><div class="item">产品介绍</div></a>
          <router-link to="/"><div class="item">客户案例</div></router-link>
          <router-link to="/price"><div :class="[curid==4?'item curItem':'item']">产品价格</div></router-link>
          <router-link to="/"><div class="item" @click="contactUs">联系我们</div></router-link>
        </div>
        <span class="curbtn" @click="change()">立即登录</span>
      </div>
      </div>
    </div>

     
 <el-dialog :visible.sync="showFlag" :before-close="handleClose" width="900px">
    <div class="loginBox">
      <div class="home_container_left">
        <div v-if="show==true">
          <div class="homeTitle">
            <span :class="cur==0?'curtab':''" @click="tab(0)">密码登录</span>
            <span :class="cur==1?'curtab':''" @click="tab(1)">验证码登录</span>
            </div>
          <div v-if="cur==0">
              <el-form
              :model="loginForm"
              :rules="rules"
              ref="ruleForm2"
              label-position="left"
              label-width="0px"
              class="demo-ruleForm login-container"
            >
              <el-form-item prop="username">
                <el-input
                  type="text"
                  v-model="loginForm.username"
                  size="large"
                  maxlength="11"
                  auto-complete="on"
                  prefix-icon="el-icon-mobile-phone"
                  placeholder="请输入用户名/手机号"
                ></el-input>
                <!-- <el-button plain>发送验证码</el-button> -->
              </el-form-item>
              <el-form-item prop="password">
                <el-input
                  type="text"
                  v-model="loginForm.password"
                  auto-complete="on"
                  maxlength="20"
                  size="large"
                  prefix-icon="el-icon-unlock"
                  placeholder="请输入密码"
                  show-password
                ></el-input>
              </el-form-item>
              <div class="rememberHeight"></div>
              <el-form-item style="width:100%;">
                <el-button
                  type="primary"
                  style="width:100%;"
                  size="large"
                  :loading="logining"
                  @click="handleSubmit"
                >登录</el-button>
              </el-form-item>
            </el-form>
          </div>
          <div v-else>
              <el-form
          :model="loginForm2"
          :rules="rules2"
          ref="ruleForm2"
          label-position="left"
          label-width="0px"
          class="demo-ruleForm login-container"
        >
          <el-form-item prop="phone">
            <el-input
              type="text"
              v-model="loginForm2.phone"
              size="large"
              maxlength="11"
              auto-complete="on"
              prefix-icon="el-icon-mobile-phone"
              placeholder="请输入手机号"
            ></el-input>
            <!-- <el-button plain>发送验证码</el-button> -->
          </el-form-item>
          <el-form-item prop="code">
          <el-input type="text" v-model="loginForm2.code" maxlength="8" size="large" prefix-icon="el-icon-key"
            placeholder="请输入验证码" style="width: 300px; float:left"></el-input>
        
          <!-- <div class="yancode">获取验证码</div> -->
           <el-button type="primary" @click="getCodes(loginForm2.phone)" class="yancode" ref="smsBtn" :disabled="smsBtnAble">
                {{smsBtn}}
          </el-button>
          </el-form-item>
          <div class="rememberHeight"></div>
          
          <el-form-item style="width:100%;">
            <el-button
              type="primary"
              style="width:100%;"
              size="large"
              :loading="logining"
              @click="phoneSubmit"
            >登录</el-button>
          </el-form-item>
            </el-form>
          </div>
          <div class="reg"><span @click="regfun(2)">还没账号？ 立即注册 》</span></div>
        </div>

        <div v-else>
          <div class="regTitle">免费注册</div>
        <el-form
          :model="regForm"
          :rules="ruleReg"
          ref="regForm"
          label-position="left"
          label-width="0px"
          class="demo-ruleForm login-container"
        >
        <el-form-item prop="username">
          <el-input
            type="text"
            v-model="regForm.username"
            size="large"
            maxlength="11"
            auto-complete="on"
            prefix-icon="el-icon-user"
            placeholder="请输入用户名"
          ></el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input
            type="text"
            v-model="regForm.password"
            auto-complete="on"
            maxlength="20"
            size="large"
            prefix-icon="el-icon-unlock"
            placeholder="请输入密码"
            show-password
          ></el-input>
        </el-form-item>
        <el-form-item prop="phone">
          <el-input
            type="text"
            v-model="regForm.phone"
            size="large"
            maxlength="11"
            auto-complete="on"
            prefix-icon="el-icon-mobile-phone"
            placeholder="请输入手机号"
          ></el-input>
          <!-- <el-button plain>发送验证码</el-button> -->
        </el-form-item>
        <el-form-item prop="code">
        <el-input type="text" v-model="regForm.verifyCode" maxlength="6" size="large" prefix-icon="el-icon-key"
          placeholder="请输入验证码" style="width: 300px; float:left"></el-input>
      
          <el-button type="primary" @click="getCodes(regForm.phone)" class="yancode" ref="smsBtn" :disabled="smsBtnAble">
                {{smsBtn}}
          </el-button>
         <!-- <div class="yancode" @click="getCodes">获取验证码</div> -->
        </el-form-item>

        <el-form-item style="width:100%;">
          <el-button
            type="primary"
            style="width:100%;"
            size="large"
            :loading="logining"
            @click="regSubmit"
          >立即注册</el-button>
        </el-form-item>
      </el-form>
      <div class="reg"><span @click="regfun(0)">已有账号， 立即登录 》</span></div>
        </div>

      </div>
      <div class="home_container_right"></div>
       </div>
     </el-dialog>
  </div>  
</template>

<script>
import { login, register, sendMsg, loginByPhone} from "@/api/login";
export default {
  name: 'topNav',
  props: ['curid','showCeng'],
  data() {
    return {
      scrollTop: 0,
      logining: false,
      show: true,
      regs: false,
      showFlag: false,
      isShows: false,
      cur: 0,
      timeNum: 0,
      loginForm: {
        username: "",
        password: "",
      },
      loginForm2: {
        phone: "",
        code: "",
      },
      regForm: {
        username: "",
        password: "",
        phone: "",
        verifyCode: "",
      },
      rules: {
        username: [{ required: true, message: "请输入用户名", trigger: "click" }],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }]
      },
      rules2: {
        phone: [{ required: true, message: "请输入手机号", trigger: "blur" }],
        code: [{ required: true, message: '请输入验证码', trigger: 'blur' }]
      },
      ruleReg: {
        username: [{ required: true, message: "请输入用户名", trigger: "click" }],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
        phone: [{ required: true, message: "请输入手机号", trigger: "blur" }],
      },
      checked: true,
      smsBtnAble: false,
      smsBtn: '发送验证码',
    };
  },
  watch: {
    showCeng: {
      deep: true,
      handler (newVal, oldVal) {
        console.log(oldVal, newVal);
        this.showFlag = newVal
      }
    }
  },
  methods: {
    contactUs() {
      this.$alert('请添加微信：wd52468546')
    },
    change() {
      this.showFlag = true;
    },
    handleScroll (e) {
      var that = this
      that.scrollTop = e.target.scrollTop
    },
    tab(n){
      console.log(n)
      this.cur = n
    },
    handleClose(){
      this.showFlag = false
      this.$emit('cancal', false);
    },
    regfun(n){
      console.log(n)
      this.show = !this.show
    },
    regSubmit(){
      var that = this
      that.logining = true
      register(that.regForm).then(res => {
        console.log(res)
        that.logining = false
        if(res.code == 200){
          window.sessionStorage.setItem('accessToken', res.data)
          // window.sessionStorage.setItem('role', 'supplier')
          that.$message({
            message: '恭喜您，注册成功',
            type: 'success'
          });
          setTimeout(() =>{
            that.$router.push({
              path: "/dashboard"
            })
            that.$emit('cancal', 'login');
          }, 1000)
        }else{
          that.$message.error(res.message)
        }
      })
    },
    getCodes(phone){
      console.log(phone)
      var that = this
      var objs = {}
      objs.phoneNumbers = phone
      if(phone==''){
        that.$message.error('请输入手机号码')
        return
      }
      sendMsg(objs).then(res => {
        console.log(res);
        if(res.code == 200){
          that.$message({
              type: 'success',
              message: '短信验证码发送成功'
          })
          let time = 60
          that.smsBtnAble = true
          let a = setInterval(() => {
            time--
            this.smsBtn = `${time}s后可再次获取`
            // this.$refs.smsBtn.$el.style = 'background:#0f0b00b4'
            if (time == 0) {
              clearInterval(a)
              // this.$refs.smsBtn.$el.style = 'background:#0f0b00'
              that.smsBtnAble = false
              that.smsBtn = '重新发送验证码'
            }
          }, 1000)
        }else{
          that.$message.error(res.message)
        }
      })
    },
    handleSubmit(){
      var that = this
      that.logining = true
      login(that.loginForm).then(res => {
        console.log(res);
        that.logining = false;
        if(res.code == 200){
          window.sessionStorage.setItem('accessToken', res.data)
          // window.sessionStorage.setItem('role', 'supplier')
          that.$message({
            message: '登录成功',
            type: 'success'
          });
          setTimeout(() =>{
            that.$router.push({
              path: "/dashboard"
            })
            that.$emit('cancal', 'login');
          }, 1000)
        }else{
          that.$message.error(res.message)
        }
      })
    },
    phoneSubmit(){
      var that = this
      that.logining = true
      loginByPhone(that.loginForm2).then(res => {
        console.log(res);
        that.logining = false;
        if(res.code == 200){
          window.sessionStorage.setItem('accessToken', res.data)
          // window.sessionStorage.setItem('role', 'supplier')
          that.$message({
            message: '登录成功',
            type: 'success'
          });
          setTimeout(() =>{
            that.$router.push({
              path: "/dashboard"
            })
            that.$emit('cancal', 'login');
          }, 1000)
        }else{
          that.$message.error(res.message)
        }
      })
      // this.$router.push({
      //   path: "/dashboard"
      // })
    }
  },
  mounted () {
    window.addEventListener('scroll', this.handleScroll, true);
  },
}
</script>
<style scoped>
.pmTop {
  height: 60px;
  display: block;
  overflow: hidden; 
  width: 100%;
  line-height: 60px;
  background: transparent;
  z-index: 10;
  position: fixed;
  top: 0;
  left: 0;
}
.curbtn {
  /* background-color: #ff7200; */
  color: #fff;
  border-radius: 4px;
  font-weight: bold;
  display: inline-block;
  height: 32px;
  padding: 0 12px;
  font-size: 14px;
  margin: 12px 0 12px 20px;
  line-height: 32px;
  cursor: pointer;
  border: 1px solid #fff;
}

  .topbox{
    width: 1200px;
    margin: 0 auto;
  }

  .logo {
    height: 60px;
    float: left;
    text-align: center;
    width: 300px;
    background: url("../assets/logo.png") no-repeat 12px center;
    background-size: auto 70%;
    display: block;
  }
  .admininfo {
    float: right;
    height: 60px;
    overflow: hidden;
  }
  .navbox{
    float:left;
    width: 720px;
    margin-right: 20px;
    display: block;
    overflow: hidden;
    height: 60px;
  }
  .item{
    float: left;
    margin: 0 18px;
    text-align: center;
    line-height:60px;
    font-size: 15px;
    display: inline-block;
    color: #fff;
    font-weight: bold;
  }
  .item:hover{
    color: #fff;
    height: 42px;
    border-bottom: 2px solid #fff;
  }
  .curItem{
    color: #fff;
    font-size: 15px;
    height: 42px;
    border-bottom: 2px solid #fff;
    display: inline-block;
  }
  .redItem{
    color: #ffcc00;
    font-weight: bold;
  }
  .navbox span {
      float:right;
      line-height:30px;
      font-size: 13px;
      color: #a0a0a0;
      padding: 0 10px;
      display: inline-block;
      cursor: pointer;
  }

.meng{
  position:fixed;
  left: 0;
  bottom: 0;
  top:0;
  right: 0;
  z-index: 9998;
  background: rgba(0, 0, 0, 0.5);
  }
.codeImg {
  text-align: center;
  position: fixed;
  z-index: 9999;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
img {
  border-radius: 6%;
  width: 240px;
}
i {
  font-size: 16px;
  color: #fff;
  position: fixed;
  right: 20px;
  top: 19px;
  cursor: pointer;
}
.topbgs{ background:#06c58f; box-shadow: 0 8px 20px 0 rgba(51, 51, 51, 0.1);}

el-input--prefix .el-input__inner{
  height: 50px;
  line-height: 50px;
  border: none;
  border-bottom: 1px solid #DCDFE6;
  border-radius: 0;
}


.loginBox{
  display: block;
  overflow: hidden;
  margin: 0px auto;
  padding: 0px;
}

.home_container{
  width: 1200px;
  display: block;
  margin: 0 auto;
}
.home_container_left {
    float: right;
    width: 450px;
    border-radius: 8px;
    background: #fff;
    overflow: hidden;
    padding: 50px;
    height: 380px;
}
.home_container_right {
    float: left;
    width: 350px;
    border-radius: 8px;
    text-align: center;
    height: 480px;
    /* box-shadow: 0 0 20px #ddd; */
    background: url(../assets/bg.jpg) no-repeat center center;
    background-size: auto 100%;
}
.logokong{
  display: block;
  width: 220px;
  height: 60px;
  font-size: 60px;
  margin: 0 auto;
}
.rememberHeight{
  height: 40px;
}
.homeTitle{
    font-size: 16px;
    color: #999;
    padding-bottom: 50px;
}
.homeTitle span{
  width: 140px;
  display: inline-block;
  cursor: pointer;
}
.homeTitle .curtab{
    font-weight: 500;
    font-size: 26px;
    color: #323233;
    cursor: default;
}
.el-button--primary {
    color: #FFF;
    background-color: #06c58f;
    border-color: #06c58f;
    padding: 16px 20px;
    border-radius:0;
}
.yancode{
  display: inline-block;
  padding: 5px 30px;
  width: 150px;
  text-align: center;
  background-color: #f3f3f3;
  color: #535353;
  font-weight: 500;
  font-size: 15px;
  cursor: pointer;
  border-radius: 40px;
  border: none;
  font-size: 12px;
  line-height:40px;
}
.regTitle{
  font-size: 24px;
  display: block;
  color: #333;
  text-align: center;
  margin-top: -30px;
  padding-bottom: 10px;
  font-weight: bold;
}
.reg{
  line-height:44px;
  font-size: 13px;
  display: block;
  text-align: center;
  color: #535353;
  cursor: pointer;
  font-weight: 500;
}
</style>
