import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)
const state={
  menuData:{}
}
const mutations={
  changeCommit(state, value) {
    console.log("mutations", state, value)
    // 修改 state 中存储的数据
    state.menuData = value
    // 修改后，页面会重新渲染
  }
}
const actions={
  changeDispatch(context, value) {
    console.log("actions", context, value)
    // 通过 commit 发送数据给 mutations
    // 接收 2 个参数：commit 中的方法名 & 发送的数据
    context.commit("changeCommit", value)
}
}
const getters={
  newNumber(){
      return state.menuData
  }
}
const store = new Vuex.Store({
  state,
  mutations,
  actions,
  getters
})

export default store
