<template>
  <div class="home">
    <topNav :curid="1" :showCeng="showFlag" @cancal="cancal"></topNav>
    <el-carousel height="560px">
    <el-carousel-item v-for="item in itemArr" :key="item.title">
       <div class="banner" :class="item.bg">
        <div class="biaoti">
          <h2>{{item.title}}</h2>
          <h3>{{item.tips}}</h3>
          <!-- <div class="curbtn" @click="changeLogin">立即登录</div> -->
        </div>
         <div class="erweima">
           <img class="codesize" :src="qrcode.qrCodeUrl">
           <div class="yishixiao" v-if="isShows">
            <p>二维码已失效</p>
            <div class="refeishi" @click="refresh()">立即刷新</div>
           </div>
           <div class="weixin">微信扫码登录</div>
         </div>
        
      </div>
    </el-carousel-item>
  </el-carousel>
    <div class="home_container">
      
      <div class="pmProTitle" style="margin-top: 40px;">帮你解决所有卖货难题</div>
      <div class="maidian">
        <div class="itemol">
           <span class="iconfont icon-jiqiren" style="font-size:166px; color:#06c58f;"></span>
           <strong>订单分发机器人</strong>
           <p>机器人自动识别群订单表格，汇总订单自动分发到仓库群（供应链），自动读取仓库群里的物流单号返给渠道群。</p>
        </div>
        <div class="itemol">
           <span class="iconfont icon-A" style="color:#f86b7c;"></span>
           <strong>一键分销你的货源</strong>
           <p>1000+优质团长在线帮卖，快速分销你的货源, 多渠道打造爆款产品</p>
        </div>
        <div class="itemol">
           <span class="iconfont icon-xiaoshoubaobiao" style="font-size:132px; color:#f9a30e;"></span>
           <strong>自动对账，实现业绩暴涨</strong>
           <p>各个渠道商和供应商对账单、自动催账、财务报表、微信汇报老板，助你业绩迅速暴涨</p>
        </div>
         <div class="itemol">
           <span class="iconfont icon-quanqiu" style="font-size:140px; color: #4398e9;"></span>
           <strong>全球优质货源直供</strong>
           <p>全球优质品牌货源直供，成熟的靠谱的供应链，解决您的后顾之忧, 并支持的第三方平台</p>
        </div>
      </div>

      <div class="pmProTitle" style="margin-top: 40px;">我们的合作伙伴</div>
      <div class="cooper">
        <div class="brands-wrapper">
                        <div class="column">
                          
                            <div class="brand">
                                <img src="//asset.ibanquan.com/s/79929/653/brand_estee?v=1619576505_1566978276">
                            </div>
                        </div>
                      	
                        <div class="column">
                          
                            <div class="brand">
                                <img src="//asset.ibanquan.com/s/79929/653/brand_xiangyibencao?v=1619576505_1566978276">
                            </div>
                        </div>
                      	
                        <div class="column">
                          
                            <div class="brand">
                                <img src="//asset.ibanquan.com/s/79929/653/brand_hanshu?v=1619576505_1566978276">
                            </div>
                        </div>
                      	
                        <div class="column">
                          
                            <div class="brand">
                                <img src="//asset.ibanquan.com/s/79929/653/brand_lap?v=1619576505_1566978276">
                            </div>
                        </div>
                      	
                        <div class="column">
                          
                            <div class="brand">
                                <img src="//asset.ibanquan.com/s/79929/653/brand_zirantang?v=1619576505_1566978276">
                            </div>
                        </div>
                      	
                        <div class="column">
                          
                            <div class="brand">
                                <img src="//asset.ibanquan.com/s/79929/653/brand_cartier?v=1619576505_1566978276">
                            </div>
                        </div>
                      	
                        <div class="column">
                          
                            <div class="brand">
                                <img src="//asset.ibanquan.com/s/79929/653/brand_baicaoji?v=1619576505_1566978276">
                            </div>
                        </div>
                      	
                        <div class="column">
                          
                            <div class="brand">
                                <img src="//asset.ibanquan.com/s/79929/653/brand_meilitianyuan?v=1619576505_1566978276">
                            </div>
                        </div>
                      	
                        <div class="column">
                          
                            <div class="brand">
                                <img src="//asset.ibanquan.com/s/79929/653/brand_anjia?v=1619576505_1566978276">
                            </div>
                        </div>
                      	
                        <div class="column">
                          
                            <div class="brand">
                                <img src="//asset.ibanquan.com/s/79929/653/brand_hengdeli?v=1619576505_1566978276">
                            </div>
                        </div>
                      	
                        <div class="column">
                          
                            <div class="brand">
                                <img src="//asset.ibanquan.com/s/79929/653/brand_cli?v=1619576505_1566978276">
                            </div>
                        </div>
                      	
                        <div class="column">
                          
                            <div class="brand">
                                <img src="//asset.ibanquan.com/s/79929/653/brand_gf?v=1619576505_1566978276">
                            </div>
                        </div>
                      	
                        <div class="column">
                          
                            <div class="brand">
                                <img src="//asset.ibanquan.com/s/79929/653/brand_per?v=1619576505_1566978276">
                            </div>
                        </div>
                      	
                        <div class="column">
                          
                            <div class="brand">
                                <img src="//asset.ibanquan.com/s/79929/653/brand_iwc?v=1619576505_1566978276">
                            </div>
                        </div>
                      	
                        <div class="column">
                          
                            <div class="brand">
                                <img src="//asset.ibanquan.com/s/79929/653/brand_chao?v=1619576505_1566978276">
                            </div>
                        </div>
                      	
                        <div class="column">
                          
                            <div class="brand">
                                <img src="//asset.ibanquan.com/s/79929/653/brand_iope?v=1619576505_1566978276">
                            </div>
                        </div>
                      	
                        <div class="column">
                          
                            <div class="brand">
                                <img src="//asset.ibanquan.com/s/79929/653/brand_te?v=1619576505_1566978276">
                            </div>
                        </div>
                      	
                        <div class="column">
                          
                            <div class="brand">
                                <img src="//asset.ibanquan.com/s/79929/653/brand_yinli?v=1619576505_1566978276">
                            </div>
                        </div>
                      	
                        <!-- <div class="column">
                          
                            <div class="brand">
                                <img src="//asset.ibanquan.com/s/79929/653/brand_jingdong?v=1619576505_1566978276">
                            </div>
                        </div>
                      	
                        <div class="column">
                            <div class="brand">
                                <img src="//asset.ibanquan.com/s/79929/653/brand_dot?v=1619576505_1566978276">
                            </div>
                        </div> -->
                      
                    	
                </div>
      </div>
</div>
     <div class="footer">Copyright@2021 kaituanyi.com
       <p style="font-size:12px; display:block; text-align:center; color:#fff"><a href="https://beian.miit.gov.cn/" target="_blank" style="text-decoration:none;color:#fff;">沪ICP备13031072号-9</a></p>
     </div>
    
    
 <!-- <el-dialog :visible.sync="showFlag" :before-close="handleClose" width="900px">
    <div class="loginBox">
      <div class="home_container_left">
        <div v-if="show==true">
          <div class="homeTitle">
            <span :class="cur==0?'curtab':''" @click="tab(0)">密码登录</span>
            <span :class="cur==1?'curtab':''" @click="tab(1)">验证码登录</span>
            </div>
          <div v-if="cur==0">
              <el-form
              :model="loginForm"
              :rules="rules"
              ref="ruleForm2"
              label-position="left"
              label-width="0px"
              class="demo-ruleForm login-container"
            >
              <el-form-item prop="username">
                <el-input
                  type="text"
                  v-model="loginForm.username"
                  size="large"
                  maxlength="11"
                  auto-complete="on"
                  prefix-icon="el-icon-mobile-phone"
                  placeholder="请输入用户名/手机号"
                ></el-input>
              </el-form-item>
              <el-form-item prop="password">
                <el-input
                  type="text"
                  v-model="loginForm.password"
                  auto-complete="on"
                  maxlength="20"
                  size="large"
                  prefix-icon="el-icon-unlock"
                  placeholder="请输入密码"
                  show-password
                ></el-input>
              </el-form-item>
              <div class="rememberHeight"></div>
              <el-form-item style="width:100%;">
                <el-button
                  type="primary"
                  style="width:100%;"
                  size="large"
                  :loading="logining"
                  @click="handleSubmit"
                >登录</el-button>
              </el-form-item>
            </el-form>
          </div>
          <div v-else>
              <el-form
          :model="loginForm2"
          :rules="rules2"
          ref="ruleForm2"
          label-position="left"
          label-width="0px"
          class="demo-ruleForm login-container"
        >
          <el-form-item prop="phone">
            <el-input
              type="text"
              v-model="loginForm2.phone"
              size="large"
              maxlength="11"
              auto-complete="on"
              prefix-icon="el-icon-mobile-phone"
              placeholder="请输入手机号"
            ></el-input>
           
          </el-form-item>
          <el-form-item prop="code">
          <el-input type="text" v-model="loginForm2.code" maxlength="8" size="large" prefix-icon="el-icon-key"
            placeholder="请输入验证码" style="width: 300px; float:left"></el-input>
      
           <el-button type="primary" @click="getCodes(loginForm2.phone)" class="yancode" ref="smsBtn" :disabled="smsBtnAble">
                {{smsBtn}}
          </el-button>
          </el-form-item>
          <div class="rememberHeight"></div>
          
          <el-form-item style="width:100%;">
            <el-button
              type="primary"
              style="width:100%;"
              size="large"
              :loading="logining"
              @click="phoneSubmit"
            >登录</el-button>
          </el-form-item>
            </el-form>
          </div>
          <div class="reg"><span @click="regfun(2)">还没账号？ 立即注册 》</span></div>
        </div>

        <div v-else>
          <div class="regTitle">免费注册</div>
        <el-form
          :model="regForm"
          :rules="rules"
          ref="regForm"
          label-position="left"
          label-width="0px"
          class="demo-ruleForm login-container"
        >
        <el-form-item prop="username">
          <el-input
            type="text"
            v-model="regForm.username"
            size="large"
            maxlength="11"
            auto-complete="on"
            prefix-icon="el-icon-user"
            placeholder="请输入用户名"
          ></el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input
            type="text"
            v-model="regForm.password"
            auto-complete="on"
            maxlength="20"
            size="large"
            prefix-icon="el-icon-unlock"
            placeholder="请输入密码"
            show-password
          ></el-input>
        </el-form-item>
        <el-form-item prop="phone">
          <el-input
            type="text"
            v-model="regForm.phone"
            size="large"
            maxlength="11"
            auto-complete="on"
            prefix-icon="el-icon-mobile-phone"
            placeholder="请输入手机号"
          ></el-input>

        </el-form-item>
        <el-form-item prop="code">
        <el-input type="text" v-model="regForm.verifyCode" maxlength="6" size="large" prefix-icon="el-icon-key"
          placeholder="请输入验证码" style="width: 300px; float:left"></el-input>
      
          <el-button type="primary" @click="getCodes(regForm.phone)" class="yancode" ref="smsBtn" :disabled="smsBtnAble">
                {{smsBtn}}
          </el-button>

        </el-form-item>

        <el-form-item style="width:100%;">
          <el-button
            type="primary"
            style="width:100%;"
            size="large"
            :loading="logining"
            @click="regSubmit"
          >立即注册</el-button>
        </el-form-item>
      </el-form>
      <div class="reg"><span @click="regfun(0)">已有账号， 立即登录 》</span></div>
        </div>

      </div>
      <div class="home_container_right"></div>
       </div>
     </el-dialog> -->
    
  </div>
</template>

<script>
import topNav from "../components/topNav.vue";
import {getQrCode, QrCode} from "@/api/login";
export default {
  name: 'Home',
  components: { topNav },
  data() {
    return {
      itemArr: [{
        bg: 'banner',
        title: '订单太多，迷失在微信群里',
        tips: '智能微信机器人，7*24 收发订单，解放你的客服'
      },{
        bg: 'banner1',
        title: '有靠谱好货，找团长开团',
        tips: '数千团长在线，一键分销你的货源'
      },{
        bg: 'banner2',
        title: '想开团赚钱，没有靠谱供应链',
        tips: '发货慢，没售后，出了问题没人管'
      }],
      showFlag: false,
      isShows: false,
      timeVal: '',
      qrcode: {}
    };
  },
  mounted(){
    this.qrcodeFun()
  },
  methods: {
    qrcodeFun(){
      var that = this
      that.qrcode = {}
      QrCode().then(res => {
        console.log(res);
        if(res.code == 200){
          that.qrcode = res.data
          that.saomagetQrCode(res.data.qrCodeId)
          that.timeVal = setInterval(() =>{
            that.saomagetQrCode(res.data.qrCodeId, that.timeVal)
          }, 2000)
        }
      })
    },
    refresh(){
      var that = this
      that.qrcodeFun();
      that.timeNum = 0
      that.isShows = false
    },
    saomagetQrCode(code, times){
      var that = this
      getQrCode(code).then(res => {
        console.log(res);
        if(res.code == 200){
          that.timeNum++
          if(res.data.isAuth){
            window.sessionStorage.setItem('accessToken', res.data.authToken)
            window.sessionStorage.setItem('role', 'supplier')
            that.$message({
              message: '恭喜您，登录成功',
              type: 'success'
            });
            clearInterval(times)
            setTimeout(() =>{
              that.$router.push({
                path: "/dashboard"
              })
              clearInterval(times)
            }, 1000)
          }

           if(that.timeNum > 30){
            that.timeNum = 0;
            that.isShows = true;
            clearInterval(times)
          }
        }
      })
    },
    changeLogin(){
      this.showFlag = true
    },
    cancal(val){
      var that = this
      if(val=='login'){
        clearInterval(that.timeVal)
      }else{
        this.showFlag = val
      }
    }
  }
}
</script>

<style scoped>
.home {
  position: relative;
  width: 100vw;
  height: 100vh;
  min-width: 1200px;
  min-height: 680px;
  overflow: auto;
  background-color: #fff;
}
.home /deep/ .el-dialog__header{
  border-bottom: none;
}
.home /deep/ .el-dialog__body{
  padding: 0;
  margin-top: -30px;
}
.home .el-dialog__body{
  padding: 0;
}

.banner {
  width: 100%;
  height: 620px;
  background: url(../assets/banner.png) no-repeat 85% 86% #07c160;
  background-size: 40%;
  display: block;
  position: relative;
  box-shadow: 0 10px 20px #eee;
  min-width: 1200px;
  overflow: hidden;
}
.banner1 {
  background: url(../assets/banner1.png) no-repeat 85% 86% #09b7e5;
  background-size: 44%;
}
.banner2 {
  background: url(../assets/banner2.png) no-repeat 85% 86% #f5206b;
  background-size: 44%;
}

.erweima{
  position: absolute;
  left: 10%;
  bottom: 150px;
  width: 180px;
  height: 180px;
  display: block;
  border: 5px solid #eee;
  background-color: #fff;
  z-index: 990;
}
.pmProTitle {
  text-align: center;
  font-size: 32px;
  color: #282828;
  display: block;
  overflow: hidden;
  font-weight: bold;
  padding: 50px 0;
  clear: both;
}
.codesize{
   width: 180px;
  height: 180px;
  display: block;
}

.banner .biaoti {
  position: absolute;
  left: 10%;
  top: 90px;
  width: 60%;
  padding: 20px 0 20px;
  display: block;
  text-align: left;
  color: #fff;
}
.banner .biaoti h2 {
  width: 1000px;
  font-size: 48px;
  font-weight: bold;
  line-height: 100px;
  color: #fff;
}
.banner .biaoti h3 {
  display: block;
  margin-bottom: 0px;
}
.weixin{
  display: block;
  text-align: center;
  font-size: 15px;color: #fff;
  padding-top:10px;
  font-weight: bold;
}
.home_container{
  width: 1200px;
  display: block;
  margin: 0 auto;
}
.maidian{
  width: 100%;
  display: block;
  clear: both;
  padding: 40px 0;
}
.itemol{
  float: left;
  width: 25%;
  display: block;
  text-align: center;
  font-size:16px;
  padding: 80px 0;
}
.itemol:hover{
  box-shadow: 0 0px 20px #ddd;
}
.itemol span{
  font-size: 160px;
  display: block;
  margin-bottom: 20px;
  height: 160px;
}
.itemol p{
  font-size: 12px;
  color: #666;
  padding: 10px 30px;
  text-align:left;
  line-height:22px;
}
.yishixiao{
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  background: rgba(0,0,0,0.7);
  display: block;
  z-index: 999;
  text-align: center;
}
.yishixiao p{
  line-height:44px;
  padding-top: 50px;
  font-size: 16px;
  color: #fff;
  font-weight: bold;
}
.refeishi{
  width: 100px;
  height: 30px;
  border: 1px solid #eee;
  border-radius: 4px;
  font-size: 13px;
  color: #fff;
  line-height:30px;
  margin: 0 auto;
  font-weight: bold;
  cursor: pointer;
}

.cooper{
  overflow: hidden;
  display: block;
  background-color: #f3f3f3;
  margin-bottom: 50px;
  padding: 5px;
}
.column {
  float: left;
  width: 188px;
  display: block;
  padding: 5px;
}

.column img{
  width: 100%; display: block;
}

.curbtn {
  background-color: #ff9900;
  color: #fff;
  border-radius: 6px;
  font-weight: bold;
  display: inline-block;
  height: 40px;
  width: 188px;
  /* border: 1px solid #ff7200; */
  box-shadow: 0 5px 0 #ff6600;
  text-align: center;
  font-size: 16px;
  margin: 10px 0;
  line-height: 40px;
  cursor: pointer;
}

</style>